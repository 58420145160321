import { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { getLoginSwal } from '@/utils/swal';
import { checkToken } from '@/api';
import { setToken } from '@/utils/cookies';
import { fetcher } from '@/utils/api';
import { internalApiPaths, screens } from '@/constants';
import useGlobalContext from '@/hooks/useGlobalContext';
import { useTranslation } from '@/i18n';
import StyledLoginForm from './styles/LoginForm';

const Input = props => {
  const [field, meta] = useField(props);
  const { onBlur, onChange, value } = field;
  const { name, type, placeholder } = props;

  return (
    <div className={`${!meta.touched || !meta.error ? 'mb-4' : ''}`}>
      <input
        className={`field shadow ${meta.touched && meta.error ? ' error' : ''}`}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
      />
      {meta.touched && meta.error ? <div className="input__error">{meta.error}</div> : null}
    </div>
  );
};

const LoginForm = ({ language }) => {
  const { t } = useTranslation();
  const { navigateTo } = useGlobalContext();
  const recaptchaRef = createRef();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const renderDevelopToken = response => process.env.SHOW_TOKEN === "1" && console.log(response.auxId); // eslint-disable-line

  const grantAccess = response => {
    if (response && response.token) {
      setToken(response.token);
      navigateTo(screens.DASHBOARD);
    }
  };

  const checkAccess = async (response, formValues) => {
    renderDevelopToken(response);

    const promise = checkToken({ email: formValues.email, token: response.token });

    const swal = getLoginSwal(promise);

    promise.then(res => {
      grantAccess(res);
      swal.close();
    });
  };

  const logIn = async (values, setSubmitting) => {
    const params = {
      ...values,
      VerificationToken: recaptchaToken,
    };

    recaptchaRef.current.reset();
    setRecaptchaToken('');

    try {
      const response = await fetcher(internalApiPaths.REQUEST_ACCESS, 'POST', null, params);
      if (response && response.token) {
        setTimeout(() => setSubmitting(false), 2000);
        checkAccess(response, values);
      }
    } catch (error) {
      setSubmitting(false);
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (recaptchaToken) {
      logIn(values, setSubmitting);
    } else {
      recaptchaRef.current.execute().then(() => setTimeout(() => setSubmitting(false), 1300));
    }
  };

  return (
    <StyledLoginForm>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t('error.email'))
            .required(t('error.required')),
          password: Yup.string().required(t('error.required')),
        })}
        onSubmit={handleSubmit}
      >
        {({ dirty, isValid, isSubmitting, submitForm }) => (
          <Form>
            <Input name="email" type="email" placeholder={t('login:email')} />
            <Input name="password" type="password" placeholder={t('login:password')} />
            <button type="submit" className="bitsa-button shadow" disabled={!dirty || !isValid || isSubmitting}>
              {t(`login:button${(isSubmitting && '--submitting') || ''}`)}
              {isSubmitting && <Spinner animation="border" size="sm" />}
            </button>
            <ReCAPTCHA
              hl={language}
              size="invisible"
              ref={recaptchaRef}
              onChange={value => {
                if (!recaptchaToken) {
                  setRecaptchaToken(value);
                  submitForm();
                }
              }}
              sitekey={process.env.RECAPTCHA_PUBLIC_KEY}
            />
          </Form>
        )}
      </Formik>
    </StyledLoginForm>
  );
};

LoginForm.propTypes = {
  language: PropTypes.string.isRequired,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default LoginForm;
