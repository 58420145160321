import styled from 'styled-components';

export default styled.div`
  display: flex;
  overflow-x: hidden;
  align-items: center;
  min-height: calc(100vh - 72px);

  h1 {
    font-size: 0.9rem;
    margin-bottom: 5px;
    align-self: flex-end;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--dark-sky-blue-two);
  }
  .row p {
    color: var(--brown-grey-two);
  }
  .footer {
    color: var(--gray-400);
  }
`;
