import fetch from 'node-fetch';
import Promise from 'bluebird';
import { externalApiPaths, DELAY_DECOUPLED_REQUEST } from './constants';
import { getProperties, handleJSONResponse } from './utils/api';

const requestAccess = (ip, params) => {
  return fetch(process.env.API_URL + externalApiPaths.REQUEST_ACCESS, {
    ...getProperties(ip),
    body: JSON.stringify(params),
  }).then(handleJSONResponse);
};

const checkTokenFn = (ip, params) =>
  fetch(process.env.API_URL + externalApiPaths.CHECK_TOKEN, {
    ...getProperties(ip),
    body: JSON.stringify(params),
  }).then(handleJSONResponse);

const checkToken = params => {
  Promise.config({
    cancellation: true,
  });
  return new Promise((resolve, _, onCancel) => {
    let cancelled = false;
    let timerInterval = '';

    onCancel(() => {
      cancelled = true;
      clearInterval(timerInterval);
      resolve(false);
    });

    const fn = () =>
      fetch('/api/websecurity/check-token', { method: 'POST', body: JSON.stringify(params) })
        .then(r => r.json())
        .then(response => {
          if (!response.result) {
            clearInterval(timerInterval);
            setTimeout(() => {
              if (!cancelled) {
                resolve(checkToken(params));
              }
            }, DELAY_DECOUPLED_REQUEST);
          } else {
            clearInterval(timerInterval);
            resolve(response.result);
          }
        })
        .catch(() => {
          clearInterval(timerInterval);
          resolve(false);
        });

    timerInterval = setInterval(fn(), DELAY_DECOUPLED_REQUEST);
  });
};

const fetchAccountMe = (key, ip, token) =>
  fetch(process.env.API_URL + key, getProperties(ip, token, 'GET')).then(handleJSONResponse);

const fetchCardsAll = (key, ip, token) =>
  fetch(process.env.API_URL + key, getProperties(ip, token, 'GET')).then(handleJSONResponse);

const fetchCardDetails = (key, ip, token, cardId) =>
  fetch(process.env.API_URL + key, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId }),
  }).then(handleJSONResponse);

const fetchTransactions = (key, ip, token, { cardId, cardType, cardSubType, offset }) =>
  fetch(process.env.API_URL + key, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, cardType, cardSubType, ...(offset && { offset }) }),
  }).then(handleJSONResponse);

const setConfiguration = (ip, token, switchValues) =>
  fetch(process.env.API_URL + externalApiPaths.SET_CONFIGURATION, {
    ...getProperties(ip, token),
    body: JSON.stringify({
      bitsa: {
        notAllowShowContact: !switchValues.shareWithContacts,
        notAllowSendToPhone: !switchValues.reciveByPhone,
      },
    }),
  }).then(handleJSONResponse);

const lockCard = (ip, token, { cardId, cardType, cardSubType }) =>
  fetch(process.env.API_URL + externalApiPaths.LOCK_CARD, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, cardType, cardSubType }),
  }).then(handleJSONResponse);

const unlockCard = (ip, token, { cardId, cardType, cardSubType }) =>
  fetch(process.env.API_URL + externalApiPaths.UNLOCK_CARD, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, cardType, cardSubType }),
  }).then(handleJSONResponse);

const changeAlias = (ip, token, alias, cardId, cardType, cardSubType) =>
  fetch(process.env.API_URL + externalApiPaths.CHANGE_ALIAS, {
    ...getProperties(ip, token),
    body: JSON.stringify({ alias, cardId, cardType, cardSubType }),
  }).then(handleJSONResponse);

const checkVoucher = (ip, token, code, cardId, cardType, cardSubType) =>
  fetch(process.env.API_URL + externalApiPaths.CHECK_VOUCHER, {
    ...getProperties(ip, token),
    body: JSON.stringify({ code, cardId, cardType, cardSubType }),
  }).then(handleJSONResponse);

const redeemVoucher = (ip, token, code, cardId, cardType, cardSubType) =>
  fetch(process.env.API_URL + externalApiPaths.REDEEM_VOUCHER, {
    ...getProperties(ip, token),
    body: JSON.stringify({ code, cardId, cardType, cardSubType }),
  }).then(handleJSONResponse);

const getIbanDepositConfiguration = (ip, token, cardId) =>
  fetch(
    process.env.API_URL + externalApiPaths.GET_IBAN_DEPOSIT_CONFIGURATION + cardId,
    getProperties(ip, token, 'GET')
  ).then(handleJSONResponse);

const getTpvRechargeConfiguration = (ip, token, cardId) =>
  fetch(
    process.env.API_URL + externalApiPaths.GET_TPV_RECHARGE__CONFIGURATION + cardId,
    getProperties(ip, token, 'GET')
  ).then(handleJSONResponse);

const performTpvRecharge = (ip, token, cardId, eurAmount) =>
  fetch(process.env.API_URL + externalApiPaths.PERFORM_TPV_RECHARGE, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, eurAmount, saveCard: false }),
  }).then(handleJSONResponse);

const getCryptoConfiguration = (ip, token) =>
  fetch(process.env.API_URL + externalApiPaths.GET_CRYPTO_CONFIGURATION, getProperties(ip, token, 'GET')).then(
    handleJSONResponse
  );

const performCryptoRecharge = (ip, token, cardId, eurAmount, paymethodSystemName) =>
  fetch(process.env.API_URL + externalApiPaths.PERFORM_CRYPTO_RECHARGE, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, eurAmount, paymethodSystemName }),
  }).then(handleJSONResponse);

const checkPaymentStatus = (ip, token, paymentId) =>
  fetch(process.env.API_URL + externalApiPaths.CHECK_PAYMENT_STATUS, {
    ...getProperties(ip, token),
    body: JSON.stringify({ paymentId }),
  }).then(handleJSONResponse);

const validateIbanTransfer = (ip, token, cardId, importe, iban, beneficiario, concepto) =>
  fetch(process.env.API_URL + externalApiPaths.VALIDATE_IBAN_TRANSFER, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, importe, iban, beneficiario, ...(concepto && { concepto }) }),
  }).then(handleJSONResponse);

const sendToIban = (ip, token, cardId, importe, iban, beneficiario, concepto) =>
  fetch(process.env.API_URL + externalApiPaths.SEND_TO_IBAN, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, importe, iban, beneficiario, ...(concepto && { concepto }) }),
  }).then(handleJSONResponse);

const checkSendToPhone = (ip, token, cardId, destinationPhonePrefix, destinationPhone, amount, note) =>
  fetch(process.env.API_URL + externalApiPaths.CHECK_SEND_TO_PHONE, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, destinationPhonePrefix, destinationPhone, amount, ...(note && { note }) }),
  }).then(handleJSONResponse);

const sendToPhone = (ip, token, cardId, destinationPhonePrefix, destinationPhone, amount, note) =>
  fetch(process.env.API_URL + externalApiPaths.SEND_TO_PHONE, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, destinationPhonePrefix, destinationPhone, amount, ...(note && { note }) }),
  }).then(handleJSONResponse);

const checkSendToCard = (ip, token, cardId, destinationCard, amount, note) =>
  fetch(process.env.API_URL + externalApiPaths.CHECK_SEND_TO_CARD, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, destinationCard, amount, ...(note && { note }) }),
  }).then(handleJSONResponse);

const sendToCard = (ip, token, cardId, destinationCard, amount, note) =>
  fetch(process.env.API_URL + externalApiPaths.SEND_TO_CARD, {
    ...getProperties(ip, token),
    body: JSON.stringify({ cardId, destinationCard, amount, ...(note && { note }) }),
  }).then(handleJSONResponse);

const fetchBitsaPlans = (ip, token) =>
  fetch(process.env.API_URL + externalApiPaths.FETCH_PLANS, getProperties(ip, token, 'GET')).then(handleJSONResponse);

const fetchBitsaPoints = (ip, token, params) =>
  fetch(process.env.API_URL + externalApiPaths.BITSA_POINTS, {
    ...getProperties(ip, token),
    body: JSON.stringify(params),
  }).then(handleJSONResponse);

const fetchLevelsConfig = (path, ip, token) =>
  fetch(process.env.API_URL + path, {
    ...getProperties(ip, token, 'GET'),
  }).then(handleJSONResponse);

const updateLevels = (path, ip, token, body) =>
  fetch(process.env.API_URL + path, {
    ...getProperties(ip, token, 'POST'),
    body: JSON.stringify(body),
  }).then(handleJSONResponse);

export {
  changeAlias,
  checkPaymentStatus,
  checkSendToCard,
  checkSendToPhone,
  checkToken,
  checkTokenFn,
  checkVoucher,
  fetchAccountMe,
  fetchBitsaPlans,
  fetchBitsaPoints,
  fetchCardDetails,
  fetchCardsAll,
  fetchLevelsConfig,
  fetchTransactions,
  getCryptoConfiguration,
  getIbanDepositConfiguration,
  getTpvRechargeConfiguration,
  lockCard,
  performCryptoRecharge,
  performTpvRecharge,
  redeemVoucher,
  requestAccess,
  sendToCard,
  sendToIban,
  sendToPhone,
  setConfiguration,
  unlockCard,
  updateLevels,
  validateIbanTransfer,
};
