import styled from 'styled-components';

export default styled.div`
  margin-top: 1.5rem;

  .field {
    border: 0;
    width: 100%;
    color: var(--brownish-grey);
    line-height: 1.5;
    font-size: 1.125rem;
    border-radius: 2rem;
    background-color: var(--white);
    padding: 0.7rem 1.2rem 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .field.error {
    border: 1px solid var(--deep-red);
  }
  .field:focus {
    outline: 0;
    color: var(--brown-grey-two);
    background-color: var(--white);
  }
  .field::placeholder {
    color: var(--cloudy-blue);
  }
  .input__error {
    color: var(--deep-red);
    font-size: 12px;
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    &:before {
      content: '❌ ';
      font-size: 10px;
    }
  }
`;
