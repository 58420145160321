import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Languages from '@/components/Languages';
import { useTranslation } from '@/i18n';
import Form from './LoginForm';
import StyledLogin from './styles/Login';

const Login = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('login');

  return (
    <StyledLogin>
      <Container>
        <Row className="align-items-center py-5">
          <Col lg="7" className="mx-auto mb-5 mb-lg-0">
            <img src="/img/login.svg" alt="" />
          </Col>
          <Col lg="5" className="px-lg-4">
            <div className="d-flex mb-4">
              <h1>{t('login:title')}</h1>
              <Languages />
            </div>
            <h2 className="mb-4">{t('login:title-secondary')}</h2>
            <p>{t('login:text')}</p>
            <Form t={t} language={language} />
          </Col>
        </Row>
        <p className="footer text-center mt-5 mb-0">BITSA © 2020</p>
      </Container>
    </StyledLogin>
  );
};

export default Login;
