import styled from 'styled-components';

export default styled.div`
  .dropdown button,
  .dropdown button:focus,
  .dropdown button:not(.disabled):not(:disabled):active,
  .dropdown.show > .btn-outline-light.dropdown-toggle {
    border: none;
    outline: none;
    box-shadow: none;
    color: var(--dark);
    background: transparent;
  }
  .dropdown button::after {
    vertical-align: 0;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 20px;
    padding: 0.8rem 0;
    user-select: none;
    margin: 0.125rem 0 0;
    min-width: auto !important;
  }
  .dropdown-menu a {
    color: var(--black-two);
    font-weight: 300;
    padding: 0.2rem 10px;
    font-size: 0.9375rem;
    background: transparent;
  }
`;
