import Dropdown from 'react-bootstrap/Dropdown';
import { LANGUAGES_AVAILABE } from '@/constants';
import { useTranslation } from '@/i18n';
import useGlobalContext from '@/hooks/useGlobalContext';
import StyledLanguages from './styles/Languages';

const Languages = () => {
  const {
    i18n: { language: i18nLang, changeLanguage },
  } = useTranslation();
  const { initialLanguage } = useGlobalContext();

  const language = i18nLang || initialLanguage;

  const handleSelect = lang => changeLanguage(lang);

  return (
    <StyledLanguages>
      <Dropdown>
        <Dropdown.Toggle variant="outline-light">
          <img src={`/img/languages/${language}.jpg`} alt={language} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {LANGUAGES_AVAILABE.filter(el => el !== language).map(el => (
            <Dropdown.Item key={el} eventKey={el} onSelect={handleSelect}>
              <img src={`/img/languages/${el}.jpg`} alt={el} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledLanguages>
  );
};

export default Languages;
